<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Stock por almacén - {{ this.document.sku }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component id="divPrint" :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
               :key="$vs.rtl">
      <div class="p-6">
        <div v-if="progress" class="mt-4 mb-4" style="width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
        <div>
          <vs-button @click="exportToExcel" color="dark" type="border" class="w-full mb-8">Descargar</vs-button>
        </div>
        <!--List-->
        <div class="mt-8">
          <vs-table class="mt-5" :data="stocks">
            <template slot="thead">
              <vs-th>Almacén</vs-th>
              <vs-th>Stock</vs-th>
              <vs-th>Series internas</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p> {{ tr.warehouse }}</p>
                  <span v-if="tr.updatedAt"
                        class="text-xs text-black">Última actualización: {{ moment(tr.updatedAt).format('DD-MM-YYYY hh:mm:ss') }}</span>
                </vs-td>

                <vs-td>
                  <vs-input v-model.number="tr.stock"></vs-input>
                </vs-td>

                <vs-td>
                  <feather-icon title="Generar series" icon="PlusIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                @click.stop="openSerialsModal(tr)"/>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
    </div>

    <!--Serials-->
    <vs-prompt
        @accept="generateAndSaveInternalSerials"
        @cancel="openIntSerialDialog = false"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series internas"
        :active.sync="openIntSerialDialog">
      <div class="con-exemple-prompt">
        <vs-textarea rows="5" label="Motivo*" v-model="reasonWhyGenerateSeries" class="mb-2 w-full"/>
        <vs-input v-model="serialsQuantity" label="Cantidad de series*" class="mb-2"></vs-input>
      </div>
    </vs-prompt>
    <!--End-->
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'
import moment from "moment";

const _ = require('lodash')

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data() {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {},
      stocks: [],
      progress: false,

      // Serials
      openIntSerialDialog: false,
      reasonWhyGenerateSeries: 'REAJUSTE DE ALMACÉN',
      serialsQuantity: 0,
      selectedWarehouse: {},
      // End
    }
  },
  watch: {
    async isSidebarActive(val) {
      if (!val) return
      this.document = {
        ...this.data
      }
      await this.getStocks(this.document)
    }
  },
  computed: {
    moment () {
      return moment
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Openm serials modal
     */
    openSerialsModal (tr) {
      this.openIntSerialDialog = true
      this.selectedWarehouse = tr
      this.serialsQuantity = tr.stock
    },
    /**
     * Get stocks
     */
    async getStocks(product) {
      try {
        this.progress = true
        this.stocks = []
        const querySnapshot = await db.collection('products').doc(product.id).collection('stocks').get()
        querySnapshot.forEach((doc) => {
          this.stocks.push({
            id: doc.id,
            ...doc.data(),
            updatedAt: doc.data().updatedAt ? doc.data().updatedAt.toDate() : null
          })
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Exports to excel
     */
    exportToExcel() {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.stocks)
        const headerVal = ['warehouse', 'stock']
        const headerTitle = ['Almacén', 'Stock']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Stock por almacén - ' + this.document.sku,
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     *Save data in firestore
     */
    async submitData () {
      try {
        this.progress = true
        for await (let s of this.stocks) {
          await db.collection('products').doc(this.document.id).collection('stocks').doc(s.id).update({
            stock: parseInt(s.stock),
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })
        }

        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'Stocks actualizados'
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Generate internal serial
     */
    async generateAndSaveInternalSerials () {
      try {
        this.progress = true
        if (this.serialsQuantity <= this.selectedWarehouse.stock) {
          const uniqueSerials = []
          for (let i = 0; i < this.serialsQuantity; i++) {
            const timestamp = new Date().getTime() + i
            const randomCode = Math.floor(Math.random() * 10000)
            uniqueSerials.push(timestamp + randomCode + "")
          }
          await this.saveInternalSerials(uniqueSerials)
          this.exportSerials(uniqueSerials)
          this.openIntSerialDialog = false
          this.selectedWarehouse = null
          this.reasonWhyGenerateSeries = 'REAJUSTE DE ALMACÉN'
        }
        else {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Estás intentando generar más series que las disponibles en el stock actual.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error al generar series.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Save internal serials
     */
    async saveInternalSerials (internalSerials) {
      const batch = db.batch()
      const arrayBatch = []
      arrayBatch.push(batch)
      let currentBatch = 0
      let countDocuments = 0

      internalSerials.forEach((s) => {
        const docRef = db.collection('serials').doc(s)
        arrayBatch[currentBatch].set(docRef, {
          createdAt: FieldValue.serverTimestamp(),
          productId: this.document.id,
          productSku: this.document.sku,
          warehouse: this.selectedWarehouse.warehouse,
          state: true,
          motive: this.reasonWhyGenerateSeries,
          status: 'free',
          uuid: auth.currentUser.uid
        })
        countDocuments++

        if (countDocuments === 499) {
          arrayBatch.push(db.batch())
          countDocuments = 0
          currentBatch++
        }
      })

      // Commit batch
      for await (let b of arrayBatch) {
        await b.commit()
      }
      // End
    },
    /**
     * Export serials
     * @param p
     */
    exportSerials (serials) {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(serials)
        const newList = []
        const headerVal = ['sku', 'serial']
        const headerTitle = ['sku', 'serial']
        list.forEach((l) => {
          newList.push({
            sku: this.document.sku,
            serial: l
          })
        })
        const data = this.formatJson(headerVal, newList)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.document.sku + '-Serials-' + new Date().getTime(),
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
