<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vx-card v-if="filter" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por categoría</label>
          <v-select label="name" :options="categories" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="categoryFilter" class="mb-4 md:mb-0"/>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por stock</label>
          <v-select :options="stocksOptions" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stockFilter" class="mb-4 md:mb-0"/>
        </div>
      </div>
    </vx-card>

    <new :compact-list="compactList"
         @add="addDataSideBar"
         :categories="categories"
         :brands="brands"
         :isSidebarActive="newSidebar"
         @closeSidebar="newSidebar= false"/>
    <update
        :compact-list="compactList"
        @update="updateDateSideBar"
        :categories="categories"
        :brands="brands"
        :isSidebarActive="updateSidebar"
        @closeSidebar="updateSidebar= false"
        :data="sidebarData"/>
    <detail :data="sidebarData"
            :isSidebarActive="detailSidebar"
            @closeSidebar="detailSidebar= false"/>

    <stocks :data="sidebarData" :isSidebarActive="showStocksSidebar"
            @closeSidebar="showStocksSidebar= false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="sidebarData = selected,updateSidebar = true">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
              </vs-dropdown-item>

              <vs-dropdown-item @click="filterData">
                            <span class="flex items-center">
                              <feather-icon icon="FilterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Filtrar</span>
                            </span>
              </vs-dropdown-item>

              <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcelTemplate">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar plantilla</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="$router.push('/warehouse/import/prices')">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Actualizar precios</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="$router.push('/warehouse/import/stocks')">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Actualizar stocks</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="$router.push('/warehouse/import/data')">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Actualizar información</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- Add new -->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="newSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="category">Categoría</vs-th>
        <vs-th sort-key="brand">Marca</vs-th>
        <vs-th sort-key="purchasePrice">Precio de compra</vs-th>
        <vs-th sort-key="price">Precio de venta</vs-th>
        <vs-th>Estado</vs-th>
        <vs-th>Servicio</vs-th>
        <vs-th>Serie</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.sku }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.name }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.category.name }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.brand.name }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', { style: 'currency', currency: 'PEN' }).format(tr.purchasePrice)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', { style: 'currency', currency: 'PEN' }).format(tr.price)
              }}</p>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchState(tr)" v-model="tr.state"/>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchService(tr)" v-model="tr.isService"/>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchSerie(tr)" v-model="tr.mandatorySeries"/>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon title="Editar" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData = tr,updateSidebar = true"/>
            <feather-icon title="Movimientos" class="ml-2" icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="detailSidebar = true, sidebarData= tr"/>
            <feather-icon title="Stock" class="ml-2" icon="PackageIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="showStocksSidebar = true, sidebarData= tr"/>
            <feather-icon title="Purchases" class="ml-2" icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="openSplotter(tr.id)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import Update from './Update'
import Detail from './Detail'
import Stocks from './Stocks'
import vSelect from 'vue-select'
import moment from "moment"

import { db } from '@/firebase/firebaseConfig'

const _ = require('lodash')
export default {
  name: 'ListProducts',
  components: {
    New,
    Update,
    Detail,
    Stocks,
    vSelect,
  },
  data () {
    return {
      itemsPerPage: 20,
      isMounted: false,
      list: [],
      compactList: [],
      initProgress: false,
      selected: null,
      filter: false,

      categories: [],
      brands: [],
      productsArisale: [],

      stocksOptions: [
        { label: 'Todos', value: -1 },
        { label: 'Sin stock', value: 0 },
        { label: 'Por acabarse', value: 10 },
      ],
      categoryFilter: {
        label: 'Todos',
        value: -1
      },
      stockFilter: {
        label: 'Todos',
        value: -1
      },

      // Data Sidebar
      newSidebar: false,
      updateSidebar: false,
      detailSidebar: false,
      showStocksSidebar: false,
      sidebarData: {},

      // Export
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      stocks: [],
      movements: []
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadProducts()
      await this.loadCategories()
      await this.loadBrands()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  watch: {
    /**
     * Filter products by category
     * @param val
     * @returns {Promise<void>}
     */
    async categoryFilter (val) {
      if (val.value !== -1) {
        this.initProgress = true
        this.list = []
        db.collection('products').where('category.id', '==', this.categoryFilter.id)
            .orderBy('createdAt', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let product = {
              id: doc.id,
              ...doc.data()
            }
            this.list.push(product)
          })
          this.initProgress = false
        }).catch((e) => {
          console.log(e)
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    },
    /**
     * Filter products by stock
     * @param val
     * @returns {Promise<void>}
     */
    async stockFilter (val) {
      if (val.value !== -1) {
        this.initProgress = true
        this.list = []
        db.collection('products').where('stock', '<=', this.stockFilter.value).get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let product = {
                  id: doc.id,
                  ...doc.data()
                }
                this.list.push(product)
              })
              this.initProgress = false
            }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    }
  },
  methods: {
    filterData () {
      this.filter = !this.filter
    },
    /**
     *  Enabled and disabled products
     **/
    switchState (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        state: product.state
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    /**
     *  Enabled and disabled products
     **/
    switchSerie (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        mandatorySeries: product.mandatorySeries
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    /**
     *  Is service?
     **/
    switchService (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        isService: product.isService
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    /**
     * Load products
     * @returns {Promise<void>}
     */
    async loadProducts () {
      this.list = []
      const querySnapshot = await db.collection('products')
          .orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let product = {
          id: doc.id,
          ...doc.data(),
          sku: doc.data().sku + '',
          type: doc.data().type ? doc.data().type : { label: 'Regular', id: 'regular' }
        }
        let compatProduct = {
          id: doc.id,
          label: doc.data().sku + '',
          name: doc.data().name
        }
        product.serie = product.serie || false
        product.isService = product.isService || false
        this.list.push(product)
        this.compactList.push(compatProduct)
      })
    },
    /**
     * Load categories
     * @returns {Promise<void>}
     */
    async loadCategories () {
      const querySnap = await db.collection('categories').orderBy('createdAt', 'desc').get()
      querySnap.forEach((doc) => {
        let category = {
          id: doc.id,
          name: doc.data().name,
          arisale: doc.data().arisale
        }
        this.categories.push(category)
      })
    },
    /**
     * Load brands
     * @returns {Promise<void>}
     */
    async loadBrands () {
      const querySnapshot = await db.collection('brands').orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let brand = {
          id: doc.id,
          name: doc.data().name,
          arisale: doc.data().arisale
        }
        this.brands.push(brand)
      })
    },
    /**
     * Get stocks
     * */
    loadStocks () {
      console.log("Entra")
      return new Promise((resolve, rejected) => {
        db.collectionGroup('stocks').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let stock = {
              id: doc.id,
              ...doc.data(),
              updatedAt: doc.data().updatedAt ? moment(doc.data().updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : null
            }
            this.stocks.push(stock)
          })
          console.log(this.stocks)
          resolve('ok')
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    /**
     * Get movements
     * */
    loadMovements () {
      return new Promise((resolve, rejected) => {
        db.collectionGroup('movements').orderBy('createdAt', 'desc')
            .where('type', 'in', ['sale', 'invoice'])
            .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let movement = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            }
            this.movements.push(movement)
          })
          resolve('ok')
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    /**
     * Reset filters and load producto
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      await this.loadProducts()
      this.initProgress = false

      this.categoryFilter = {
        label: 'Todos',
        value: -1
      }
      this.stockFilter = {
        label: 'Todos',
        value: -1
      }
      this.$refs.filterCard.removeRefreshAnimation()
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Exports to excel
     */
    async exportToExcel () {
      try {
        this.initProgress = true
        await this.loadStocks()
        await this.loadMovements()
        import('@/vendor/Export2Excel').then(excel => {
          const list = _.cloneDeep(this.list)
          list.forEach((i) => {
            i.category = i.category.name
            i.brand = i.brand.name
            i.local1 = 0
            i.local2 = 0
            i.updatedAtLocal2 = null
            i.local3 = 0
            i.local4 = 0
            i.sales = []
            i.purchases = []
            const stocks = []
            // Search stock
            this.stocks.forEach((o) => {
              if (o.productId === i.id) {
                stocks.push(o)
              }
            })
            // Search movement
            this.movements.forEach((m) => {
              if (m.productId === i.id) {
                if (m.type === 'sale') {
                  i.sales.push(m)
                } else if (m.type === 'invoice') {
                  i.purchases.push(m)
                }
              }
            })
            // Movements
            if (i.purchases.length !== 0) {
              i.purchaseDate = i.purchases[0].createdAt
              i.quantityPurchase = i.purchases[0].quantity
            } else {
              i.purchaseDate = 'NN'
              i.quantityPurchase = 0
            }
            if (i.sales.length !== 0) {
              i.lastSaleDate = i.sales[0].createdAt
              i.quantitySale = i.sales[0].quantity
              i.store = i.sales[0].warehouse.name
            } else {
              i.lastSaleDate = 'NN'
              i.quantitySale = 0
              i.store = 'NN'
            }
            // End
            // Days passed
            if (i.purchaseDate instanceof Date) {
              i.diffInDays = Math.ceil((new Date().getTime() - i.purchaseDate.getTime()) / (1000 * 3600 * 24))
              i.diffInMonths = parseFloat((i.diffInDays / 30).toFixed(2))
            } else {
              i.diffInDays = 0
              i.diffInMonths = 0
            }
            // Days without rotation
            if (i.lastSaleDate instanceof Date && i.purchaseDate instanceof Date) {
              i.daysWithoutRotation = Math.ceil((new Date().getTime() - i.lastSaleDate.getTime()) / (1000 * 3600 * 24))
              i.monthsWithoutRotation = parseFloat((i.daysWithoutRotation / 30).toFixed(2))
            } else if (i.purchaseDate instanceof Date) {
              i.daysWithoutRotation = Math.ceil((new Date().getTime() - i.purchaseDate.getTime()) / (1000 * 3600 * 24))
              i.monthsWithoutRotation = parseFloat((i.daysWithoutRotation / 30).toFixed(2))
            } else {
              i.daysWithoutRotation = 0
              i.monthsWithoutRotation = 0
            }

            // End
            if (stocks.length !== 0) {
              // 447
              const local1 = stocks.find((s) => s.id === 'qJATEgtu7wmFGNTz5iUu')
              if (local1) {
                i.local1 = parseInt(local1.stock)
              }
              // 461
              const local2 = stocks.find((s) => s.id === 'yTQWCsmKjTYocaJznPV2')
              if (local2) {
                i.local2 = parseInt(local2.stock)
                i.updatedAtLocal2 = local2.updatedAt ? moment(local2.updatedAt).format('YYYY-MM-DD hh:mm:ss') : '-'
              }
              // Leticia
              const local3 = stocks.find((s) => s.id === 'WWtVqLHzvQzUa3dvWTzN')
              if (local3) {
                i.local3 = parseInt(local3.stock)
              }
              // Almacén
              const local4 = stocks.find((s) => s.id === 'iTqjua9ei559n8Dksxg7')
              if (local4) {
                i.local4 = parseInt(local4.stock)
              }
            }
            i.stock = i.local1 + i.local2 + i.local3 + i.local4
            if (i.purchaseDate instanceof Date) {
              i.purchaseDate = i.purchases[0].createdAt.getDate() + '/' + (i.purchases[0].createdAt.getMonth() + 1) + '/' + i.purchases[0].createdAt.getFullYear()
            }
            if (i.lastSaleDate instanceof Date) {
              i.lastSaleDate = i.sales[0].createdAt.getDate() + '/' + (i.sales[0].createdAt.getMonth() + 1) + '/' + i.sales[0].createdAt.getFullYear()
            }
          })
          const headerVal = ['id', 'name', 'sku', 'category', 'brand', 'stock', 'local1', 'local2', 'updatedAtLocal2', 'local3', 'local4', 'price', 'purchasePrice',
            'purchaseDate', 'quantityPurchase', 'lastSaleDate', 'quantitySale', 'store', 'diffInDays', 'diffInMonths', 'daysWithoutRotation', 'monthsWithoutRotation']

          const headerTitle = ['ID', 'Nombre', 'SKU', 'Categoria', 'Marca', 'Stock', 'Stock 447',
            'Stock 461', 'Última actualización', 'Stock Leticia', 'Almacén', 'Precio venta', 'Precio compra',
            'Fecha de compra', 'Cantidad', 'Fecha última venta', 'Cantidad', 'Tienda', 'Días Transcurridos', 'Meses Transcurridos', 'Días sin rotación', 'Meses sin rotación']

          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: 'Listado de productos',
            autoWidth: this.cellAutoWidth,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Export template
     */
    exportToExcelTemplate () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.list)
        const headerVal = ['id', 'name', 'sku', 'brand', 'purchasePrice', 'price', 'stock']
        const headerTitle = ['ID', 'Name', 'SKU', 'BRAND', 'BUYPRICE', 'SALEPRICE', 'STOCK']
        list.forEach((l) => l.brand = l.brand.name)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Template Importacion Masiva',
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Open splotter
     */
    openSplotter (id) {
      window.open('https://imv-splotter.firebaseapp.com?id=' + id, '_blank')
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
